import React from 'react';

function About() {
  return (
    <section className="py-20 min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <div className="max-w-7xl px-1 md:px-1 lg:px-1">
        <h2 className="text-3xl font-semibold mb-6 text-black text-center">About Us</h2>
        <p className="text-lg text-gray-700 mb-6 text-center">
          TechThrill Solutions LLP is a premier IT consulting firm committed to delivering cutting-edge technology solutions that empower businesses to succeed in the ever-evolving digital landscape. With a focus on innovation, we offer a wide range of services tailored to meet the unique needs of organizations across various industries. Our expertise spans cloud computing, DevOps, cybersecurity, data analytics, and enterprise software development, ensuring our clients stay ahead of the curve.
        </p>
        <p className="text-lg text-gray-700 text-center">
          At TechThrill Solutions, we pride ourselves on fostering long-term partnerships through personalized service, deep technical knowledge, and a results-driven approach. Our team of experienced professionals is passionate about leveraging the latest advancements in technology to optimize business operations, enhance productivity, and drive sustainable growth. Whether you’re a startup looking to scale or an established enterprise seeking to modernize, we are your trusted partner on the journey to digital transformation.
        </p>
      </div>
    </section>
  );
}

export default About;