import React from 'react';
import ServicesCarousel from './ServicesCarousel';

function Services() {
  return (
    <section className="py-40 min-h-screen bg-gray-80">
      <h2 className="text-2xl font-semibold mb-4 text-black text-center">Our Services</h2>
      <ServicesCarousel />
    </section>
  );
}

export default Services;
