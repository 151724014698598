// src/Home.js
import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import './Home.css'; // Import a CSS file to handle styling if needed

function Home() {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen" id="home">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        src="/videos/home.mp4" 
        autoPlay
        loop
        muted
      />

      {/* Overlay Content */}
      <div className="relative z-10 text-center text-white">
        <h2 className="text-2xl font-semibold mb-4">Welcome to TechThrill Solutions</h2>
        <p className="text-lg mb-8 px-4">
          We provide top-notch IT consulting services to help your business thrive in the digital age.
        </p>
        <Link
          to="services" // Target section
          smooth={true}
          duration={500}
          className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition duration-300 cursor-pointer"
        >
          Learn More
        </Link>
      </div>

      {/* Overlay for background tint (optional) */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-0"></div>
    </div>
  );
}

export default Home;