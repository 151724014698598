import React from 'react';
import Slider from 'react-slick';

// const services = [
//   {
//     title: 'Software Development',
//     description: 'We offer custom software development services tailored to your business needs.',
//     imageUrl: '/images/services/software-development.jpeg',
//   },
//   {
//     title: 'Cloud Solutions',
//     description: 'Our cloud solutions help you scale your business efficiently and securely.',
//     imageUrl: '/images/services/cloud-solutions.jpeg',
//   },
//   // Existing services
//   {
//     title: 'Cybersecurity',
//     description: 'Protect your business data with our comprehensive cybersecurity services.',
//     imageUrl: '/images/services/cybersecurity.png',
//   },
//   {
//     title: 'IT Consulting',
//     description: 'Get expert advice to optimize your IT infrastructure and operations.',
//     imageUrl: '/images/services/it-consulting.png',
//   }
//   // Add other services as needed
// ];

const services = [
  {
    title: 'Software Development',
    description: 'We offer custom software development services tailored to your business needs, specializing in creating scalable, high-performance applications using the latest technologies.',
    imageUrl: '/images/services/software-development.jpeg',
  },
  {
    title: 'Cloud Solutions',
    description: 'Our cloud solutions provide robust scalability and security, helping businesses leverage cloud technology to enhance operational efficiency and reduce costs.',
    imageUrl: '/images/services/cloud-solutions.jpeg',
  },
  {
    title: 'Cybersecurity',
    description: 'Protect your business data with our comprehensive cybersecurity services, offering advanced threat detection, risk assessment, and proactive protection strategies.',
    imageUrl: '/images/services/cybersecurity.png',
  },
  {
    title: 'IT Consulting',
    description: 'Get expert advice to optimize your IT infrastructure and operations. Our consultants provide strategic insights that align with your business goals, ensuring technology drives your success.',
    imageUrl: '/images/services/it-consulting.png',
  }
];

const ServicesCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <Slider {...settings}>
      {services.map((service, index) => (
        <div
          key={index}
          className="flex items-center justify-center w-full h-[70vh] bg-gray-80 py-20"
        >
          <div className="flex flex-col md:flex-row items-stretch w-full max-w-screen-xl mx-auto">
            <div className="md:w-1/2 p-8 flex items-center bg-gray-80">
              <div className="text-center md:text-left">
                <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                <p className="text-lg text-gray-700">{service.description}</p>
              </div>
            </div>
            <div className="md:w-1/2 p-8 flex items-center justify-center">
              <img
                src={service.imageUrl}
                alt={service.title}
                className="w-full h-full object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default ServicesCarousel;
