import React from 'react';

function ContactUs() {
  return (
    <section className="py-20 min-h-screen flex flex-col items-center justify-center bg-gray-80">
      <h2 className="text-2xl font-semibold mb-4 text-black">Contact Us</h2>
      <div className="flex flex-col md:flex-row items-center justify-center w-full px-4">
        <div className="mb-8 md:mb-0 md:mr-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d24689.664427645897!2d77.03601824612058!3d28.519602720974255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1722951199635!5m2!1sen!2sin%22"
            width="750"
            height="550"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Google Map"
          ></iframe>
        </div>
        <form className="bg-white p-8 rounded-lg shadow-md w-full md:w-1/2">
          <h3 className="text-xl font-semibold mb-4 text-black">Send Us a Message</h3>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="name">Your Name</label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>          
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2" htmlFor="query">Query</label>
            <textarea
              id="query"
              name="query"
              className="w-full px-3 py-2 border rounded-lg"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-black text-white px-6 py-2 rounded-full hover:bg-gray-800 transition duration-300"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
