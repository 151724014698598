// src/App.js
import React from 'react';
import { Link as ScrollLink, Element } from 'react-scroll';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import './App.css';

function App() {
  return (
    <div>
      <header className="fixed w-full bg-white shadow">
        <nav className="flex justify-around p-4">
          <ScrollLink to="home" smooth={true} duration={500} className="cursor-pointer">Home</ScrollLink>
          <ScrollLink to="services" smooth={true} duration={500} className="cursor-pointer">Services</ScrollLink>
          <ScrollLink to="about" smooth={true} duration={500} className="cursor-pointer">About Us</ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500} className="cursor-pointer">Contact</ScrollLink>
        </nav>
      </header>
      <main className="pt-16">
        <Element name="home">
          <Home />
        </Element>
        <Element name="services">
          <Services />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Element name="contact">
          <Contact />
        </Element>
      </main>
    </div>
  );
}

export default App;
